import { Controller } from "@hotwired/stimulus";
import { get } from "@rails/request.js";
import * as Sentry from "@sentry/browser";

import videojs from "video.js";
import "@videojs/http-streaming";
import hlsQualitySelector from "jb-videojs-hls-quality-selector";

videojs.registerPlugin("hlsQualitySelector", hlsQualitySelector);

export default class extends Controller {
  static targets = ["player"];
  static outlets = ["vimeo-player"];

  static values = {
    vimeoId: String,
    thumbnailUrl: String,
    autoplay: Boolean,
    nextChapterUrl: String
  };

  connect() {
    this.setupPlayer();
    this.setupPlayerReadyCallback();
    this.fetchAndLoadEvent();
    this.initializeVideoEndedHandler();
    this.initializePlaybackRateChangeHandler();
    this.setupPlaybackRate();

    if (this.autoplayValue) {
      this.play();
    }
  }

  setupPlayer() {
    this.player = videojs(this.playerTarget, {
      controls: true,
      fluid: true,
      playbackRates: [0.5, 0.75, 1, 1.25, 1.5, 2],
      poster: this.thumbnailUrlValue,
      html5: {
        vhs: { overrideNative: true },
        nativeVideoTracks: false,
        nativeAudioTracks: false,
        nativeTextTracks: false
      }
    });
  }

  setupPlayerReadyCallback() {
    this.player.ready(() =>
      videojs.getPlugin("hlsQualitySelector")
        ? this.player.hlsQualitySelector({ displayCurrentQuality: true })
        : console.error("HLS Quality Selector plugin is not available.")
    );
  }

  async fetchAndLoadEvent() {
    try {
      const response = await fetch(`/api/v1/videos/${this.vimeoIdValue}`);
      this.handleErrorIfUnsuccessful(response);

      const data = await response.json();
      this.loadEventIntoPlayer(data);

      this.player.one('loadedmetadata', () => {
        this.selectAudioTrackBasedOnDomain(data);
      });

      this.removeVimeoPlayer();
    } catch (error) {
      console.error("Error:", error);
      Sentry.captureException(error);

      this.vimeoPlayerOutletElement.classList.remove("hidden");
      this.element.remove();
    }
  }

  handleErrorIfUnsuccessful(response) {
    if (!response.ok) {
      throw Error(`HTTP error! status: ${response.status}`);
    }
  }

  loadEventIntoPlayer(data) {
    this.player.src({ type: "application/x-mpegURL", src: data.hls_playlist_url });
    this.player.load();
  }

  selectAudioTrackBasedOnDomain(data) {
    const { language_code } = data;
    const audioTrackList = this.player.audioTracks();

    for (let i = 0; i < audioTrackList.length; i++) {
      if (audioTrackList[i].language === language_code) {
        audioTrackList[i].enabled = true;

        break;
      }
    }
  }

  removeVimeoPlayer() {
    this.vimeoPlayerOutletElement.remove();
  }

  initializeVideoEndedHandler() {
    this.player.on("ended", async () => {
      if (this.nextChapterUrlValue.length === 0) return;
      if (this.autoplayValue === false) return;

      await get(this.nextChapterUrlValue, {
        responseKind: "turbo-stream"
      });
    });
  }

  initializePlaybackRateChangeHandler() {
    this.player.on("ratechange", () => {
      localStorage.setItem("playbackRate", this.player.playbackRate());
    });
  }

  setupPlaybackRate() {
    this.player.on("canplay", () => {
      this.playbackRate = parseFloat(localStorage.getItem("playbackRate"));

      if (this.playbackRate) {
        this.player.playbackRate(this.playbackRate);
      }
    });
  }

  play() {
    this.player.play().catch((error) => {
      switch (error.name) {
        case "PasswordError":
          console.warn("Cannot play password protected video.");
          break;
        case "PrivacyError":
          console.warn("Cannot play this video due to privacy error.");
          break;
        case "NotAllowedError":
          this.handleNotAllowedError();
          break;
        default:
          console.warn("Error playing Vimeo video.");
          break;
      }
    });
  }

  handleNotAllowedError() {
    console.warn("Autoplay prevented by browser policy, Waiting for user interaction.");

    const play = function () {
      this.play();

      document.body.removeEventListener("click", play);
    }.bind(this);

    document.body.addEventListener("click", play);
  }

  disconnect() {
    if (this.player) {
      this.player.dispose();
    }
  }
}
