import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = {
    itemPrice: Number,
  }

  connect(){
    this.recalculatePrice();
  }

  static targets = ["quantity", "total"]

  recalculatePrice() {
    if (this.quantity < 1) {
      this.quantityTarget.value = 1
    }

    this.totalTarget.innerText = this.total
  }

  incrementQuantity() {
    this.quantityTarget.value = parseInt(this.quantityTarget.value) + 1

    this.recalculatePrice()
  }

  decrementQuantity() {
    this.quantityTarget.value = parseInt(this.quantityTarget.value) - 1

    this.recalculatePrice()
  }

  get total() {
    return parseFloat(this.quantity * this.itemPrice).toFixed(2)
  }

  get quantity() {
    return parseInt(this.quantityTarget.value)
  }

  get itemPrice() {
    return parseFloat(this.itemPriceValue).toFixed(2)
  }
}
